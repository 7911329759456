






































import { Component, Prop, Vue } from 'vue-property-decorator';
import Sticky from '@/shared/resources/components/Sticky.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';

@Component({
  components: {
    GridSpacer,
    GridCol,
    GridRow,
    Sticky,
  },
})
export default class MainHeader extends Vue {
  /**
   * Props
   */
  @Prop() private title!: string;
  @Prop() private subtitle?: string;

  /**
   * Data
   */
  private fixed: boolean = false;

  /**
   * Display getters
   */
  private get displaySubtitle(): boolean {
    return !!this.subtitle;
  }

  private get displayButtons(): boolean {
    return !!this.$slots.buttons;
  }
}

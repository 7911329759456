


























import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import DeliveriesPrices from '@/app/modules/deliveries/components/DeliveriesPrices.vue';

import { bankData } from '@/shared/lib/config/generalConfig';
import Divider from '@/shared/resources/components/Divider.vue';

@Component({
  components: {
    Divider,
    DeliveriesPrices,
    Card,
    GridCol,
    GridRow,
    Alert,
    MainHeader,
  },
})
export default class InformationView extends Vue {
  /**
   * Getters
   * @private
   */
  private get bankData(): string {
    return bankData;
  }
}












import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class Sticky extends Vue {
  /**
   * Refs
   */
  @Ref() private stickyElement!: HTMLElement;

  /**
   * Props
   */
  @Prop({ default: false, type: Boolean }) private bottom!: boolean;
  @Prop({ default: false, type: Boolean }) private grey!: boolean;
  @Prop({ default: false, type: Boolean }) private white!: boolean;
  @Prop({ default: false, type: Boolean }) private header!: boolean;
  @Prop({ default: false, type: Boolean }) private inCard!: boolean;
  @Prop({ default: 0 }) private margin!: number;

  /**
   * Data
   */
  private fixed: boolean = false;
  private topPx: number = 0;
  private bottomPx: number = 0;
  private resizeTimeout: any = null;

  /**
   * Styles
   */
  private get styles(): object {
    if (this.header) {
      return {};
    }

    return {
      top: `${this.topPx}px`,
    };
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'sticky': true,
      'sticky--bottom': this.bottom,
      'sticky--grey': this.grey,
      'sticky--white': this.white,
      'sticky--in-card': this.inCard,
    };
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.calculateTop();
  }

  private mounted() {
    // document.addEventListener('scroll', this.onScroll);
    // window.addEventListener('resize', this.onResize);

    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('sticky--is-fixed', e.intersectionRatio < 1),
      {
        threshold: [1],
      },
    );

    observer.observe(this.stickyElement);

  }

  private destroyed() {
    // document.removeEventListener('scroll', this.onScroll);
    // window.removeEventListener('resize', this.onResize);
  }

  /**
   * Methods
   */
  private calculateTop() {
    if (this.header) {
      return;
    }

    const header: HTMLElement | null = document.getElementById('mainHeader');
    this.topPx = header ? this.margin + header.clientHeight : this.margin;
  }

  private calculateBottom() {
    const header: HTMLElement | null = document.getElementById('mainHeader');
    this.topPx = header ? this.margin + header.clientHeight : this.margin;
  }

  /**
   * Handlers
   */
  private onScroll() {
    this.fixed = this.stickyElement.getBoundingClientRect().y === this.topPx;
  }

  private onResize() {
    clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(() => {
      this.calculateTop();
    }, 10);
  }
}

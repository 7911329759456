const bankData: string = '' +
  '<p><b>FIRMA HANDLOWO USŁUGOWA PROARTI ARTUR CHOJNACKI</b></p>' +
  '<p>ul. Przemysłowa 54,<br/>\n' +
  '62-510 Konin</p>' +
  '<p>Nr konta:<br/>\n' +
  '<b>07 1140 2017 0000 4102 1290 6229</b> (mBank)</p>';

const bankDataAccountNumber: string = '07 1140 2017 0000 4102 1290 6229';

export {
  bankData,
  bankDataAccountNumber,
};

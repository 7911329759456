


































































import { Vue, Component } from 'vue-property-decorator';

import Locale from '@/core/locale/Locale';

import { formatAmount } from '@/core/helpers/utils/NumberUtils';
import ListItem from '@/shared/resources/components/lists/ListItem.vue';
import ListItemContent from '@/shared/resources/components/lists/ListItemContent.vue';
import ListItemTitle from '@/shared/resources/components/lists/ListItemTitle.vue';
import ListItemAction from '@/shared/resources/components/lists/ListItemAction.vue';
import ListItemSubtitle from '@/shared/resources/components/lists/ListItemSubtitle.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import { minOrderString } from '@/app/modules/online-orders/config/onlineOrders';
import Payments from '@/shared/lib/support/Payments';
import { PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';

@Component({
  components: {
    ListItemSubtitle,
    ListItemAction,
    ListItemTitle,
    ListItemContent,
    ListItem,
    Divider,
  },
})
export default class DeliveriesPrices extends Vue {
  /**
   * Getters
   */
  private get paymentsTypesConfig(): PaymentsTypeInterface[] {
    return Payments.getPaymentsTypes();
  }

  private get minOrderString(): string {
    return minOrderString();
  }

  /**
   * Methods
   */
  private displayPrice(price: number, replaceZero: boolean = true) {
    return price === 0 && replaceZero
      ? this.$t('common.gratis')
      : `${formatAmount(price)} ${Locale.currencySymbol}`;
  }

  private format(price: number) {
    return `${formatAmount(price)} ${Locale.currencySymbol}`;
  }
}
